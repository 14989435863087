// Utilities
@import "utilities/variables";
@import "utilities/suneditor";

// Base
@import "../css/bootstrap.min.css";
@import "../css/fontawesome.min.css";
@import "../css/admin.min.css";
@import "base";

// Components
@import "components/components";

:root {
  --brand-color: #12aeb4;
}

.sales-navbar {
  border-radius: 0% 0% 1.5% 1.5% / 25%;
}

.returning-customer {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #0a1629;
  background-color: #e6edf5;
  border-radius: 23px;
  padding: 10px 30px;
  margin-right: 25px;
  margin-bottom: 0px;
}
.event-date {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #7d8592;
  padding-left: 2px;
  margin-bottom: 20px;
}

.explore-plans-card {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 1em;
  min-height: 350px;
  height:auto;
}

.explore-plans-card span {
  font-weight: bold;
}

.explore-plans-card .plan-name {
  display: flex;
  justify-content: space-between;
}

.purchase-button {
  width: 100%;
  min-width: 100%;
  border: none;
}

.hover-text {
  position: relative;
  width: 100%;
}

.tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  color: black;
  font-size: 12px;
  background-color: #eff0f3;
  border-radius: 10px;
  padding: 5px;
}

.hover-text:hover .tooltip-text {
  visibility: visible;
  width: 100%;
  top: -5em;
  font-size: 0.9rem;
  right: 0%;
}

.slide-card__actions {
  display: flex;
  gap: 0.25rem;
  justify-content: flex-end;
  align-items: center;
  margin: 0.25rem 0px;
  font-size: 0.75rem;
}

.common-enroll-btn,
.enroll-text,
.loading-text,
.enrolled-span {
  font-weight: bold;
  font-size: 0.75rem;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
}

.common-enroll-btn {
  font-weight: bold;
  color: black;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.enrolled-span {
  color: black;
  /* Matching the button text color */
  background-color: transparent;
  /* If you want enrolled text to have no background */
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.slide-card__img_wrapper {
  position: relative;
}

.slide-card__img_wrapper__img {
  width: 100%;
  height: 13em;
  object-fit: fill;
  border-radius: 8px;
}

.slide-card__title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 8px;
  margin-top: 8px;
  word-break: break-word;
  word-wrap: break-word;
  flex: auto;
}

// .se-component figure {
//   //height: 28em !important;
//   width: 100% !important;
//   padding-bottom: 0.5em !important;
//   overflow: hidden;
//   object-fit: contain;
// }

.enduser-dashboard .profile #account-details .plans,
.enduser-dashboard .profile #account-details .explore-plans {
  background: #d8ddf6;
  box-shadow: 0px 3px 15px rgba(119, 119, 119, 0.1607843137);
  border: 1px solid #e8e8e8;
  border-radius: 1em;
  margin-right: 2.5em;
  margin-bottom: 3em;
  width: 18em !important;
  height: 29em;
}

.enduser-dashboard .profile #account-details .plans .card-body,
.enduser-dashboard .profile #account-details .explore-plans .card-body {
  padding: 20px;
}

.hover-text {
  position: relative;
  width: 100%;
}

.tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  color: black;
  font-size: 12px;
  background-color: #93979a36;
  border-radius: 10px;
  padding: 5px;
}

.hover-text:hover .tooltip-text {
  visibility: visible;
  width: 100%;
  top: -5em;
  font-size: 0.9rem;
  right: 0%;
}

#user-welcome .user-welcome-note {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #7a869a;
  margin-bottom: 17.41px;
  text-align: justify;
}

.startCompleteIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -7em;
  margin-top: -20em;
}

.startCompleteIcon img {
  width: 30px;
  height: 30px;
  margin-bottom: 1em;
}

.hover-container {
  position: relative;
}

.tooltip-text {
  visibility: hidden;
  position: absolute;
  top: -2em;
  background-color: #d8ddf6;
  padding: 2px;
  font-size: 0.9rem;
  margin-bottom: 3em;
}

.hover-container:hover .tooltip-text {
  visibility: visible;
}

.download-data {
  width: 2em !important;
  height: 2em !important;
  display: inline-block;
}

.download-data .info-tooltip {
  visibility: hidden;
  width: 150px;
  color: black;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  right: 10%;
  top: -1em;
  z-index: 1;
}

.download-data:hover .info-tooltip {
  visibility: visible;
}

.highlight-card {
  background: #ffffff;
  box-shadow: 5px 5px 20px rgba(60, 118, 119, 0.2784313725);
  border: 3px solid #ebebeb;
  border-radius: 13px;
  padding: 10px;
  height: auto !important;

  &:hover {
    border: 3px solid var(--brand-color);
  }
}

.highlight-card-sub {
  background: #ffffff;
  box-shadow: 5px 5px 20px rgba(60, 118, 119, 0.2784313725);
  border: 3px solid #ebebeb;
  border-radius: 13px;
  padding: 10px;
  height: 18.5em !important;

  &:hover {
    border: 3px solid var(--brand-color);
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: var(--brand-color) !important;
}

.invoice-btn {
  height: 2.9em;
  width: 5em;
  border-radius: 9px;
  color: #fff;
  border: none;
  background-color: var(--brand-color);
  box-shadow: 5px 5px 10px var(--brand-color);
}

.description-content img {
  height: 22em;
  width: 100%;
  max-width: 40em;
}

// .text-content {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

.text-content img {
  height: 22em;
  width: 100%;
  display: block;
  object-fit: fill;
}

.audio-player-wrapper {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.audio-player-wrapper audio {
  width: 100%;
}

// .se-component img {
//   height: 30em;
//   width: 100%;
//   object-fit: contain;
// }

.dashboard-news {
  img {
    height: 22em;
    width: auto;
    max-width: 40em;
  }
}

.wisdome-icon {
  color: #c2c7d0;

  &:hover,
  &:active,
  &:focus {
    color: var(--bs-white);
  }
}

/* Styling for the 404 page */
.not-found-page {
  text-align: center;
  color: #333;
  padding: 2rem;
}

.not-found-container {
  max-width: 600px;
  margin: auto;
  margin-top: 12%;
}

.error-code {
  font-size: 7rem;
  font-weight: bold;
  color: #211930;
  margin-bottom: 1rem;
}

.error-message {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.home-button {
  display: inline-block;
  padding: 1rem 2rem;
  background-color: #6567e6;
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 5px;
  border: none;
}

.highlight-card-folder {
  background: #ffffff;
  box-shadow: 5px 5px 20px rgba(60, 118, 119, 0.2784313725);
  border: 3px solid #ebebeb;
  border-radius: 13px;
  padding: 10px;
  height: 22.7em;
}
.highlight-card-folder:hover {
  border: 3px solid var(--brand-color);
}

.search-socialWall {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  background: #ffffff;
  box-shadow: 0px 3px 9px #7284cc;
  border: 1px solid #e5e5e5;
  height: 58px;
  padding-right: 52px;
  position: relative;
}

.search-socialWall input {
  width: 100%;
  height: 100%;
  box-shadow: none;
  border: none;
}

.search-socialWall input:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}

.search-icon-social {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.load-more-button {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  border-radius: 6px;
  width: 150px;
  height: 42px;
  border: none;
  text-align: center !important;
}

.icon-button {
  background: none;
  border: none;
  outline: none !important;
  cursor: pointer;
}
.icon-button:focus {
  outline: none !important;
}

.courses-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: flex-start;
}

.course-item {
  flex: 0 0 250px;
  margin-right: 1rem;
}

.sales-page-image {
  height: 170px;
  width: 100%;
  img {
    border-radius: 8px;
    height:100%;
  }
}
.sales-page-content{
  height: 134px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.certificate-and-enroll-button {
  display: flex;
  align-items: center;
  gap: 1em;
}

.download-certificate-button {
  width: 15em;
  margin-left: 30%;
  margin-right: 40%;
  margin-top: 2em;
  border: none;
}