@import "../../utilities/variables";

.attachedLessonFiles__tab-btns {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  gap: 0.7rem;
  margin-bottom: 2rem;

}

.attachedLessonFiles__tab-btn {
  background-color: var(--brand-color);
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.attachedLessonFiles__tab-btn--active {
  border: 4px solid var(--brand-color-light);
}

.attachedLessonFiles__tab-btn:hover .attachedLessonFiles__tab-btn-logo{
  filter: none;
}

.attachedLessonFiles__tab-btn-logo {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 auto;
  filter: invert(0.5);
}

.attachedLessonFiles__tab-btn--active .attachedLessonFiles__tab-btn-logo {
  filter: none;
}

.attachedLessonFiles__tab-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
}

.attachedLessonFiles__tab-content-doc {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem;
  background-color: var(--brand-color);
  border-radius: 5px;
  box-sizing: border-box;
}

.attachedLessonFiles__file-name {
  flex: 1;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  color: white;
}

.attachedLessonFiles__file-download {
  cursor: pointer;
  margin-left: 0.5rem;
}

.attachedLessonFiles__tab-content-docs-item-download-logo {
  width: 1.5rem;
  height: 1.5rem;
}

/* Media query for smaller screens */
@media (max-width: 800px) {
  .attachedLessonFiles__tab-content-doc {
    flex-direction: column;
    align-items: flex-start;
  }

  .attachedLessonFiles__file-name {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .attachedLessonFiles__file-download {
    align-self: flex-start;
    margin-left: 0;
  }
}