.modal-success {
  .modal-content {
    border-radius: 20px;
  }
  .success-img {
    margin-top: -100px;
    margin-left: -70px;
  }
  .title {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 28px;
    line-height: 50px;
    color: #0c0a08;
    margin-bottom: 24px;
  }
  .title {
    font-size: 22px;
    line-height: 44px;
    margin-bottom: 10px;
  }
  .text {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 19px;
    line-height: 34px;
    color: #a6a7ae;
    margin-bottom: 19px;
  }
  .text {
    font-size: 14px;
    line-height: 24px;
  }
  @media only screen and (max-width: 1600px) {
    .modal-dialog {
      max-width: 608px;
    }
  }
  @media only screen and (max-width: 1440px) {
    .modal-dialog {
      max-width: 402px;
    }
  }
  .modal-body {
    padding-left: 16px;
    padding-right: 16px;
  }
  .success-img {
    margin-top: -58px;
    margin-left: -35px;
    width: 300px;
  }
}
